(function ($) {
  let slideBarToggle = null;
  let slideBarClose = null;

  function toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();

    const $toggle = $(this);
    const $li = $toggle.closest('.dropdown');
    const $subnav = $li.children('.dropdown__subnav-wrapper');

    if ($li.hasClass('opened')) {
      $li.removeClass('opened');
      $subnav.slideUp('fast');
    } else {
      $li.addClass('opened');
      $subnav.slideDown('fast');
    }
  }

  function prepareDropdownForNav($nav) {
    ///// INIT SLIDER POSITION /////
    $nav.find('.active').addClass('opened');

    $nav
      .find('.dropdown')
      .filter(':not(.opened)')
      .each(function () {
        $(this).children('.dropdown__subnav-wrapper').slideUp(0);
      });

    ///// INIT EVENTS /////
    $nav.find('.dropdown__toggle').on('click', toggleDropdown);
  }

  function closeSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', false);
    slideBarClose.attr('aria-expanded', false);
    $('body').removeClass('mobile-menu-open');
    $(document).off('click.close_slidebar').off('keyup.close_slidebar');
  }

  function tryClosingSlidebarMenu(event) {
    // Keyboard
    if ('undefined' !== typeof event.keyCode) {
      // ESCAPE
      if (27 === event.keyCode) {
        $('.vtx-burger-container').focus();
        closeSlidebarMenu();
      }
    }
    // Click
    else if (!$(event.target).closest('#mobile-menu-wrapper').length) {
      closeSlidebarMenu();
    }
  }

  function openSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', true);
    slideBarClose.attr('aria-expanded', true);
    $('body').addClass('mobile-menu-open');
    $(document)
      .on('click.close_slidebar', tryClosingSlidebarMenu)
      .on('keyup.close_slidebar', tryClosingSlidebarMenu);
  }

  function initMenuMobile() {
    slideBarToggle = $('.vtx-burger-container');
    slideBarClose = $('#mobile-menu-close');

    const $mobileMenu = $('#mobile-menu');

    slideBarToggle.on('click', function (event) {
      event.stopPropagation();

      if ($('body').hasClass('mobile-menu-open')) {
        closeSlidebarMenu();
      } else {
        openSlidebarMenu();
      }
    });

    slideBarClose.on('click', function (event) {
      event.stopPropagation();

      if ($('body').hasClass('mobile-menu-open')) {
        closeSlidebarMenu();
      }
    });

    prepareDropdownForNav($mobileMenu.find('.nav-mobile'));
  }

  $(document).ready(function () {
    initMenuMobile();
  });
})(jQuery);
