import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const breadcrumbs = document.querySelector('.breadcrumbs-carousel');
  const breadcrumbLinks = document.querySelectorAll(
    '.breadcrumbs-carousel__item__link'
  );

  if (0 < breadcrumbLinks.length) {
    const breadcrumbsCarousel = new Swiper(breadcrumbs, {
      slidesPerView: 'auto',
      spaceBetween: 4,
      watchOverflow: true,
      a11y: {
        firstSlideMessage: _x(
          'This is the first slide',
          'carousel first slide label',
          'vtx'
        ),
        lastSlideMessage: _x(
          'This is the last slide',
          'carousel first slide label',
          'vtx'
        ),
        paginationBulletMessage: _x(
          'Go to slide {{index}}',
          'carousel navigation label',
          'vtx'
        ),
        prevSlideMessage: _x(
          'Previous slide',
          'carousel previous label',
          'vtx'
        ),
        nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
      },
      on: {
        sliderMove: (swiper) => {
          if (swiper.isBeginning) {
            swiper.el.classList.add('reached-start');
          } else {
            swiper.el.classList.remove('reached-start');
          }
          if (swiper.isEnd) {
            swiper.el.classList.add('reached-end');
          } else {
            swiper.el.classList.remove('reached-end');
          }
        },
      },
    });

    breadcrumbLinks.forEach((link) => {
      link.addEventListener('focus', () => {
        const slide = link.closest('.swiper-slide');
        const slideIndex = Array.prototype.indexOf.call(
          breadcrumbs.querySelector('.swiper-wrapper').children,
          slide
        );
        breadcrumbsCarousel.slideTo(slideIndex);
        if (breadcrumbsCarousel.isBeginning) {
          breadcrumbsCarousel.el.classList.add('reached-start');
        } else {
          breadcrumbsCarousel.el.classList.remove('reached-start');
        }
        if (breadcrumbsCarousel.isEnd) {
          breadcrumbsCarousel.el.classList.add('reached-end');
        } else {
          breadcrumbsCarousel.el.classList.remove('reached-end');
        }
      });
    });
  }
});
