import domReady from '@wordpress/dom-ready';

domReady(() => {
  const limit = 200;
  let timer = null;

  function adjustBtnVisibility() {
    const st = window.scrollY;

    if (st <= limit) {
      document.body.classList.add('floating-button-hidden');
    } else if (
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.offsetHeight
    ) {
      document.body.classList.add('floating-button-hidden');
    } else {
      document.body.classList.remove('floating-button-hidden');
    }
  }

  window.addEventListener('scroll', () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      // actual callback
      adjustBtnVisibility();
    }, 50);
  });

  adjustBtnVisibility();
});
