import $ from 'jquery';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function initSelects() {
  const selects = document.querySelectorAll(
    'select.custom-select, .gform_wrapper select, .variations_form select, .woocommerce-ordering select, .facetwp-type-sort select'
  );

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        create: false,
        maxItems: 1,
        controlInput: null,
      });
    });
  }
}

function changeFileUpload(formID) {
  // select right file uploads
  let singleFileUploads;
  if (formID) {
    const form = document.querySelector('form[data-formid="' + formID + '"');
    singleFileUploads = form.querySelectorAll('.gfield--fileupload-single');
  } else {
    singleFileUploads = document.querySelectorAll('.gfield--fileupload-single');
  }
  // single file uploads
  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach(function (fileUpload) {
      const input = fileUpload.querySelector('input[type="file"]');
      const label = fileUpload.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');

      // create elements
      const wrapper = document.createElement('div');
      const removeBtn = document.createElement('button');
      const uploadBtn = document.createElement('p');
      const uploadBtnIcon = document.createElement('span');
      const uploadBtnLabel = document.createElement('span');
      const filePlaceholder = document.createElement('p');

      // add classes
      wrapper.classList.add('fake-upload');
      filePlaceholder.classList.add('fake-upload__file-placeholder');
      uploadBtn.classList.add('fake-upload__upload-btn');
      uploadBtnLabel.classList.add('fake-upload__upload-btn__label');
      uploadBtnIcon.classList.add('fake-upload__upload-btn__icon');
      uploadBtnIcon.classList.add('icon-download');
      removeBtn.classList.add('fake-upload__remove-btn');

      // add text contents
      removeBtn.innerHTML =
        '<span class="sr-only">' +
        _x('Clear file', 'file upload', 'vtx') +
        '</span><span class="icon-x"></span>';
      uploadBtnLabel.textContent = _x('Select a file', 'file upload', 'vtx');
      filePlaceholder.textContent = input.value ? input.value : noFileLabel;

      // extra attributes
      removeBtn.setAttribute('type', 'button');

      // append elements
      uploadBtn.appendChild(uploadBtnIcon);
      uploadBtn.appendChild(uploadBtnLabel);
      wrapper.appendChild(uploadBtn);
      wrapper.appendChild(filePlaceholder);
      wrapper.appendChild(removeBtn);
      label.after(wrapper);

      input.addEventListener('change', function () {
        if (input.value) {
          const filename = input.value.replace(/^C:\\fakepath\\/i, '');
          filePlaceholder.textContent = filename;
          removeBtn.classList.add('visible');
        } else {
          filePlaceholder.textContent = noFileLabel;
          removeBtn.classList.remove('visible');
        }
      });

      input.addEventListener('focus', function () {
        wrapper.classList.add('has-focus');
      });

      input.addEventListener('blur', function () {
        wrapper.classList.remove('has-focus');
      });

      removeBtn.addEventListener('click', function () {
        input.value = '';
        filePlaceholder.textContent = noFileLabel;
        removeBtn.classList.remove('visible');
      });
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function addFormEvents() {
  const fields = document.querySelectorAll('.gfield');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
        'gfield--fileupload',
        'large-label',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      if (
        field.classList.contains('field_description_above') &&
        field.classList.contains('gfield--has-description')
      ) {
        return;
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            if (subinput) {
              changeFormLabel(sublabel, subinput);
            }
          });
        }
      } else {
        field.classList.add('initialized');

        const label = field.querySelector('.gfield_label');
        const input = field.querySelector(
          '.ginput_container input , .ginput_container textarea'
        );

        if (label && input) {
          changeFormLabel(label, input);
        }
      }
    });
  }
}

$(document).on('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload(formID);
  addFormEvents();
  initSelects();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    addFormEvents();
    initSelects();
  }
});
